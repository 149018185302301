const $ = require('jquery');
import slick from 'slick-carousel';

$(function(){

'use strict';

/*******************************************
 * 
 * mv slider
 * 
 *******************************************/

(function() {

	// element
	var $elm = $('.top-Mainvisual__slider');

	// var
	
	// init
	function init() {
		setup();
	}

	//func
	function setup() {
		$elm.slick({
			dots: true,
			arrows: true,
			autoplay : true,
			fade : true,
			speed: 3000,
			autoplaySpeed: 5000,
			pauseOnHover: false,
			pauseOnFocus: false,
			touchMove: false
		});
	}

	init();

})();

/*******************************************
 *******************************************/
});