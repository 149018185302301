const $ = require('jquery');
import slick from 'slick-carousel';

$(function(){

'use strict';

/*******************************************
 * 
 * shop slider
 * 
 *******************************************/

(function() {

	// element
	var $elm = $('.shop-LunchSlider');
	var $elm_slider = $elm.find('.shop-LunchSlider__slider');
	var $elm_navi = $elm.find('.shop-LunchSlider__navi');

	// var
	
	// init
	function init() {
		setup();
	}

	//func
	function setup() {
		$elm_slider.slick({
			dots: true,
			arrows: true,
			autoplay : false,
			fade : false,
			speed: 600,
			autoplaySpeed: 2000,
			pauseOnHover: false,
			pauseOnFocus: false,
			touchMove: false
		});

		$elm_navi.find('._item').each(function(){
			var _index = $(this).attr('data-target');
			$(this).on('click',function(){
				$elm_slider.slick('slickGoTo', _index);
			});
		});
	}

	init();

})();

/*******************************************
 *******************************************/
});