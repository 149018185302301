const $ = require('jquery');

$(function(){

'use strict';

/*******************************************
 * 
 *  viewport
 * 
********************************************/

(function() {

	// element

	// var
	
	// init
	function init() {
		setup();
	}

	// func
	function setup() {
		if(window.screen.width<375){
			setViewportForSe();
		}
	}

	function setViewportForSe(){
		document.querySelector("meta[name='viewport']").setAttribute("content", "width=375");
	}

	init();

})();

/*******************************************
 * 
 *  add _blank to external link 
 * 
********************************************/

(function() {

	// Elements
	var $elm = $("a[href^='http://'],a[href^='https://']");

	// Vars
	var exarr = [
		"localhost",
		"192.168.",
		"127.0.0.1"
	];

	// init
	function init() {
		exarr.push(location.hostname);
		$elm.each(function(){
			if(typeof $(this).attr('target') != 'undefined') return;
			for(var i=0;i<exarr.length;++i){
				if(!$(this).attr('href').indexOf("http://"+exarr[i])) return;
				if(!$(this).attr('href').indexOf("https://"+exarr[i])) return;
			}
			$(this).attr('target','_blank');
		});
	}

	init();

})();

/*******************************************
 * 
 *  navibutton
 * 
********************************************/

(function() {

	// Elements
	var $elm_button = $('.header-NaviButton');
	var $elm_navi = $('.main-MenuPanel');
	var $elm_header = $('.header-Main');

	// Vars

	// init
	function init() {
		$elm_button.on('click', function(){
			$(this).toggleClass('is-active');
			if($(this).hasClass('is-active')){
				$elm_navi.fadeIn(300);
				$elm_header.addClass('is-navi_opened');
				$('html,body').addClass('is-navi_opened');
			}else{
				$elm_navi.fadeOut(300);
				$elm_header.removeClass('is-navi_opened');
				$('html,body').removeClass('is-navi_opened');
			}
		});
	}

	init();

})();


/*******************************************
 * 
 *  scroll link
 * 
********************************************/

(function() {

	// Elements
	var $elm = $("a[data-type=scroll]");
	var $elm_button = $('.header-NaviButton');
	var $elm_navi = $('.main-MenuPanel');
	var $elm_header = $('.header-Main');

	// Vars
	var $speed = 400;

	// init
	function init() {
		$elm.each(function(){
			$(this).on('click', function(){
				var $href = $(this).attr("href");
				var $index = $href.indexOf("#");
				var $target = $($href.slice($index));
				if($target.length){
					var position = $target.offset().top;
					$('body,html').animate({scrollTop:position}, $speed, 'swing');
					if($elm_button.hasClass('is-active')){
						$elm_navi.fadeOut(300);
						$elm_header.removeClass('is-navi_opened');
						$('html,body').removeClass('is-navi_opened');
					}
					return false;
				}
			});
		});
	}

	init();

})();


/*******************************************
 * 
 *  scrolling
 * 
********************************************/

(function() {

	// Elements
	var $elm_header = $('.header-Main');
	var $elm_upbutton = $('.main-UpButton');

	var $elm_footer = $('.footer-Main');
	
	// Vars

	// init
	function init() {
		scrolling();
		$(window).scroll(scrolling);
	}

	function scrolling(){
		var t = $(window).scrollTop();
		if(t>10){
			//under
			$elm_header.removeClass('is-top');
			$elm_header.addClass('is-middle');
		}else{
			//upside
			$elm_header.addClass('is-top');
			$elm_header.removeClass('is-middle');
		}

		if(t>200){
			//under
			if(!$elm_upbutton.hasClass('is-active')){
				$elm_upbutton.addClass('is-active');
			}
		}else{
			//upside
			if($elm_upbutton.hasClass('is-active')){
				$elm_upbutton.removeClass('is-active');
			}
		}

		var wh = $(window).innerHeight();
		var f = $elm_footer.offset().top;
		var upbutton_high = parseInt($elm_upbutton.css('bottom')) + parseInt($elm_upbutton.css('height'))/2;

		if((t+wh)>(f+upbutton_high)){
			var off = (t+wh) - (f+upbutton_high);
			$elm_upbutton.css('transform','translateY(-'+off+'px)');
		}else{
			$elm_upbutton.css('transform','translateY(0px)');
		}
		
	}

	init();

})();

/*******************************************
 * 
 *  upbutton
 * 
********************************************/

(function() {

	// Elements
	var $elm_upbutton = $('.main-UpButton');
	
	// Vars

	// init
	function init() {
		setup();
	}

	function setup(){
		$elm_upbutton.click(function() {
			$('body,html').animate({scrollTop:0}, 400, 'swing');
		});
	}

	init();

})();

/*******************************************
 *
 *  upbutton
 *
********************************************/

(function() {

	// Vars
    var $animElem = $('.animElem');
    var animatedClass = 'animated';
    var delayInit = 300;
    var delay = 300;

	// init
	function init() {
		setup();
	}

	function setup(){
        animateAddClass();
        $(window).on('scroll', function(){
            animateAddClass();
        });
	}
	function animateAddClass() {
        var triggerPositionY = $(window).scrollTop() + $(window).innerHeight();
        $animElem.each( function(){
            if (!$(this).hasClass(animatedClass)) {
                if ( $(this).offset().top < triggerPositionY ) {
                    // $(this).addClass(animatedClass);
                    var delay = $(this).attr('data-delay')? $(this).attr('data-delay'): delayInit;
                    setTimeout(function(){
                        $(this).addClass(animatedClass);
                    }.bind(this), delay);
                }
            }
        });
	}

	init();

})();

/*******************************************
********************************************/
});
