const $ = require('jquery');
import validate from 'jquery-validation';
//window.AjaxZip3 = require('./ajaxzip3.js');

$(function(){

'use strict';

/*******************************************
 * 
 * order validation
 * 
********************************************/

(function() {

	// element
	var $elm_form = $('form[data-form=order]');
	
	// var

	// init
	function init() {
		$elm_form.validate({
			rules : {
				form_name1 : { required: true },
				form_name2 : { required: true },
				form_mail : { required: true, email: true },
				form_agreement : { required: true },
			},
			groups: {
				namegroup: "form_name1 form_name2",
				kanagroup: "form_kana1 form_kana2",
			},

			errorElement : "error",
			errorClass: "error",
			messages: {
				form_name1 : {
					required: "この項目は必須です。"
				},
				form_name2 : {
					required: "この項目は必須です。"
				},
				form_mail : {
					required: "この項目は必須です。",
					email: "メールアドレスが正しくありません。",
				},
				form_agreement : {
					required: "プライバシーポリシーをご確認ください。"
				},
			},
		    errorPlacement: function(error, element){
		        if(element.is("select")){
		            error.appendTo( element.parent().parent() );
		        }else if( (element.attr("name")=="form_name1") || (element.attr("name")=="form_name2") ){
		            error.appendTo( element.parent() );
		        }else if( (element.attr("name")=="form_kana1") || (element.attr("name")=="form_kana2") ){
		            error.appendTo( element.parent() );
		        }else if(element.is("input[name='form_agreement'")){
		            error.appendTo( element.parent().parent().parent() );
		        }else if(element.is("input[type='radio'")){
		            error.appendTo( element.parent().parent().parent().parent() );
		        }else if(element.is("input[type='checkbox'")){
		            error.appendTo( element.parent().parent().parent().parent() );
		        }else{
		            // This is the default behavior 
		            error.insertAfter( element );
		        }
		    },
			highlight: function(element, errorClass, validClass) {
		        if($(element).is("input[type='radio'")){
					var $elm = $(element).parent().parent().parent().parent().find('._box');
					$elm.addClass(errorClass).removeClass(validClass);
		        }else if($(element).is("input[type='checkbox'")){
					var $elm = $(element).parent().parent().parent().parent().find('._box');
					$elm.addClass(errorClass).removeClass(validClass);
		        }else{
		            // This is the default behavior 
					$(element).addClass(errorClass).removeClass(validClass);
		        }
			},
			unhighlight: function(element, errorClass, validClass) {
		        if($(element).is("input[type='radio'")){
					var $elm = $(element).parent().parent().parent().parent().find('._box');
					$elm.removeClass(errorClass).addClass(validClass);
		        }else if($(element).is("input[type='checkbox'")){
					var $elm = $(element).parent().parent().parent().parent().find('._box');
					$elm.removeClass(errorClass).addClass(validClass);
		        }else{
		            // This is the default behavior 
					$(element).removeClass(errorClass).addClass(validClass);
		        }
			}
		});
		
	}

	function func() {
		
	}

	init();

})();

/*******************************************
 * 
 * form button
 * 
********************************************/

(function() {

	// element
	var $elm_form = $('form[data-form=order]');
	var $elm_button_confirm = $elm_form.find('[data-form=order_confirm]');
	var $elm_button_back = $elm_form.find('[data-form=order_back]');
	var $elm_button_submit = $elm_form.find('[data-form=order_submit]');

	// var

	// init
	function init() {
		
		$elm_button_confirm.on('click', function (event) {
			event.preventDefault();
			$elm_form.submit();
		});
		$elm_button_back.on('click', function (event) {
			event.preventDefault();
			$elm_form.find('[name=act]').val('entry');
		    $elm_form.submit();
		});
		$elm_button_submit.on('click', function (event) {
			event.preventDefault();
		    $elm_form.submit();
		});
		
	}

	function func() {
		
	}

	init();

})();

/*******************************************
 * 
 * form test
 * 
********************************************/

(function() {

	// element
	var $elm_button = $('[data-form=order_test]');

	// var

	// init
	function init() {
		$elm_button.on('click', function () {
			$('[name=form_item_1]').val('2');
			$('[name=form_item_2]').val('4');
			$('[name=form_item_3]').val('6');
			//$('[name=form_item_4]').val('8');
			$('[name=form_item_5]').val('8');

			$('[name=form_name1]').val('姓姓姓');
			$('[name=form_name2]').val('名名名');
			$('[name=form_kana1]').val('せいせいせい');
			$('[name=form_kana2]').val('めいめいめい');
			$('[name=form_mail]').val('test2@e-p-web.com');
			$('[name=form_tel]').val('111');

			$('[name=form_zip1]').val('530');
			$('[name=form_zip2]').val('0001');
			$('[name=form_area]').val('沖縄県');
			$('[name=form_address1]').val('住所１住所１住所１住所１');
			$('[name=form_address2]').val('住所２住所２住所２住所２');
			
			$('[name=form_comment]').val('これはテストメールです。正式なお問い合わせではありません。');
		});
	}

	function func() {
		
	}

	init();

})();

/*******************************************
 * 
 * number console
 * 
********************************************/

(function() {

	// element
	var $elm = $('._numberSelectLabel');

	// var

	// init
	function init() {
		$elm.each(function(){
			var $elm_select = $(this).find('select');
			var $elm_plus = $(this).find('._numberSelectLabel-plus');
			var $elm_minus = $(this).find('._numberSelectLabel-minus');
			$elm_plus.on('click',function(){
				numberplus($elm_select)
			});
			$elm_minus.on('click',function(){
				numberminus($elm_select)
			});
		});
	}

	function numberplus($elm_select) {
		var num = $elm_select.val();
		num -= 0;
		if(num<10) num += 1;
		$elm_select.val(num);
	}

	function numberminus($elm_select) {
		var num = $elm_select.val();
		num -= 0;
		if(num>0) num -= 1;
		$elm_select.val(num);
	}

	init();

})();

/*******************************************
 * 
 * zipcode button
 * 
********************************************/

(function() {

	// element
	var $elm_button = $('._button[data-form=zip]');

	// var

	// init
	function init() {
		$elm_button.on('click',function(){
			AjaxZip3.zip2addr(
				'form_zip1',
				'form_zip2',
				'form_area',
				'form_address1'
			);
		});
	}
	
	init();

})();

/*******************************************
********************************************/
});