const $ = require('jquery');
import validate from 'jquery-validation';

$(function(){

'use strict';

/*******************************************
 * 
 * conatct validation
 * 
********************************************/

(function() {

	// element
	var $elm_form = $('form[data-form=contact]');
	
	// var

	// init
	function init() {
		$elm_form.validate({
			rules : {
				form_kind : { required: true },
				form_companyname : { required: true },
				form_name1 : { required: true },
				form_name2 : { required: true },
				form_mail : { required: true, email: true },
				form_tel : { required: true },
				form_comment : { required: true },
				form_agreement : { required: true },
			},
			groups: {
				namegroup: "form_name1 form_name2"
			},

			errorElement : "error",
			errorClass: "error",
			messages: {
				form_kind : {
					required: "この項目は必須です。"
				},
				form_companyname : {
					required: "この項目は必須です。"
				},
				form_name1 : {
					required: "この項目は必須です。"
				},
				form_name2 : {
					required: "この項目は必須です。"
				},
				form_mail : {
					required: "この項目は必須です。",
					email: "メールアドレスが正しくありません。",
				},
				form_tel : {
					required: "この項目は必須です。"
				},
				form_comment : {
					required: "この項目は必須です。"
				},
				form_agreement : {
					required: "プライバシーポリシーをご確認ください。"
				},
			},
		    errorPlacement: function(error, element){
		        if(element.is("select")){
		            error.appendTo( element.parent().parent() );
		        }else if( (element.attr("name")=="form_name1") || (element.attr("name")=="form_name2") ){
		            error.appendTo( element.parent() );
		        }else if(element.is("input[name='form_agreement'")){
		            error.appendTo( element.parent().parent().parent() );
		        }else if(element.is("input[type='radio'")){
		            error.appendTo( element.parent().parent().parent().parent() );
		        }else if(element.is("input[type='checkbox'")){
		            error.appendTo( element.parent().parent().parent().parent() );
		        }else{
		            // This is the default behavior 
		            error.insertAfter( element );
		        }
		    },
			highlight: function(element, errorClass, validClass) {
		        if($(element).is("input[type='radio'")){
					var $elm = $(element).parent().parent().parent().parent().find('._box');
					$elm.addClass(errorClass).removeClass(validClass);
		        }else if($(element).is("input[type='checkbox'")){
					var $elm = $(element).parent().parent().parent().parent().find('._box');
					$elm.addClass(errorClass).removeClass(validClass);
		        }else{
		            // This is the default behavior 
					$(element).addClass(errorClass).removeClass(validClass);
		        }
			},
			unhighlight: function(element, errorClass, validClass) {
		        if($(element).is("input[type='radio'")){
					var $elm = $(element).parent().parent().parent().parent().find('._box');
					$elm.removeClass(errorClass).addClass(validClass);
		        }else if($(element).is("input[type='checkbox'")){
					var $elm = $(element).parent().parent().parent().parent().find('._box');
					$elm.removeClass(errorClass).addClass(validClass);
		        }else{
		            // This is the default behavior 
					$(element).removeClass(errorClass).addClass(validClass);
		        }
			}
		});
		
	}

	function func() {
		
	}

	init();

})();

/*******************************************
 * 
 * form button
 * 
********************************************/

(function() {

	// element
	var $elm_form = $('form[data-form=contact]');
	var $elm_button_confirm = $elm_form.find('[data-form=contact_confirm]');
	var $elm_button_back = $elm_form.find('[data-form=contact_back]');
	var $elm_button_submit = $elm_form.find('[data-form=contact_submit]');

	// var

	// init
	function init() {
		
		$elm_button_confirm.on('click', function (event) {
			event.preventDefault();
			$elm_form.submit();
		});
		$elm_button_back.on('click', function (event) {
			event.preventDefault();
			$elm_form.find('[name=act]').val('entry');
		    $elm_form.submit();
		});
		$elm_button_submit.on('click', function (event) {
			event.preventDefault();
		    $elm_form.submit();
		});
		
	}

	function func() {
		
	}

	init();

})();

/*******************************************
 * 
 * form test
 * 
********************************************/

(function() {

	// element
	var $elm_button = $('[data-form=contact_test]');

	// var

	// init
	function init() {
		$elm_button.on('click', function () {
			$('[name=form_kind]').eq(2).prop('checked', true);
			$('[name=form_name1]').val('姓姓姓');
			$('[name=form_name2]').val('名名名');
			$('[name=form_companyname]').val('会社名会社名会社名');
			$('[name=form_tel]').val('111');
			$('[name=form_mail]').val('test2@e-p-web.com');
			$('[name=form_agreement]').prop('checked', true);
			$('[name=form_comment]').val('これはテストメールです。正式なお問い合わせではありません。');
		});
	}

	function func() {
		
	}

	init();

})();

/*******************************************
********************************************/
});